import * as React from "react"

/* let React, { Suspense } = "react";
const Customer = React.lazy(() => let("../components/Frame.js")); */
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

/* PİPELİNE FOR IMPORTS
DEFINE VAR WITH THE ABSOLUTE PATH IN THE /public/ FOLDER
ADD VAR TO ARRAY

IF ADDING NEW TAB,
DEFINE NEW ARRAY
EXPORT SAID ARRAY
UPDATE THE IF STATEMENT IN THE ARTWORK.JS
*/

import Layout from "../components/layout"


import HeaderDark from "../components/headerdark"



/* const Frame = React.lazy(() => let("../components/Frame.js"));
 */
/* DONT FORGET Gifs */

/* write a while loop here */

const test = () => (
    <Layout>
<HeaderDark/>
       
    </Layout>
/*     </React.Suspense> */

)





export default test;

